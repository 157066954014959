import { Environment, PlatformDomain, PlatformID, PlatformName } from '@wezacommon/ng-models';
import { createPlatformUrl } from '@wezacommon/ng-utils';

const platform = {
	id: PlatformID.APPS_LOCAL,
	name: PlatformName.APPS,
	subDomain: PlatformDomain.LOCAL,
	url: createPlatformUrl(PlatformDomain.LOCAL, PlatformID.APPS_LOCAL),
};

export const environment: Environment = {
	production: false,
	firebase: {
		apiKey: 'AIzaSyB7h5nkJIxfTNW1SlCusFSafG6EVoFEG2E',
		authDomain: 'weza-development.firebaseapp.com',
		databaseURL: 'https://weza-development-default-rtdb.firebaseio.com',
		projectId: 'weza-development',
		storageBucket: 'weza-development.appspot.com',
		messagingSenderId: '94957476718',
		appId: '1:94957476718:web:db78512e61e023dc0aff7f',
	},
	recaptcha: {
		siteKey: '6LcdwFEjAAAAAGhlQhcEGIOPNqRNSNrprEm7Bjqv',
	},
	platform,
	url: {
		baseUrl: 'https://playpen.weza.business',
		accounts: `http://localhost:${PlatformID.ACCOUNTS_LOCAL}`,
		apps: `http://localhost:${PlatformID.APPS_LOCAL}`,
		compliance: `http://localhost:${PlatformID.COMPLIANCE_LOCAL}`,
		invoicing: `http://localhost:${PlatformID.INVOICING_LOCAL}`,
		backoffice: `http://localhost:${PlatformID.BACKOFFICE_LOCAL}`,
	},
};
