import { Pipe, PipeTransform } from "@angular/core"

/**
 * Round off to the nearest 10
 */
@Pipe({
	name: "round"
})
export class RoundPipe implements PipeTransform {
	transform(input?: number | null): number | null {
		if (!input || Number.isNaN(input)) {
			return null
		}

		return Math.round(input)
	}
}
