import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
	selector: 'wezacommon-form-error',
	templateUrl: './form-error.component.html',
	styleUrls: ['./form-error.component.scss'],
})
export class FormErrorComponent {
	@Input() key: string | undefined;
	@Input() errors!: ValidationErrors | null;

	public getError(): string {
		return this.errors && this.key && this._hasErrors()
			? this.errors[this.key].message
			: '';
	}

	private _hasErrors(): boolean {
		return this.errors && this.key && this.errors[this.key];
	}
}
