import { MatCardModule } from "@angular/material/card"
import { MatTabsModule } from "@angular/material/tabs"
import { MatRadioModule } from "@angular/material/radio"
import { MatSnackBarModule } from "@angular/material/snack-bar"
import { MatNativeDateModule } from "@angular/material/core"
import { MatDatepickerModule } from "@angular/material/datepicker"
import { MatCheckboxModule } from "@angular/material/checkbox"
import { MatListModule } from "@angular/material/list"
import { MatIconModule } from "@angular/material/icon"
import { MatSidenavModule } from "@angular/material/sidenav"
import { MatToolbarModule } from "@angular/material/toolbar"
import { MatButtonModule } from "@angular/material/button"
import { MatInputModule } from "@angular/material/input"
import { MatPaginatorModule } from "@angular/material/paginator"
import { MatTableModule } from "@angular/material/table"
import { MatSelectModule } from "@angular/material/select"
import { MatStepperModule } from "@angular/material/stepper"
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner"
import { MatFormFieldModule } from "@angular/material/form-field"

export const MaterialDesign = [
	MatCardModule,
	MatInputModule,
	MatButtonModule,
	MatToolbarModule,
	MatSidenavModule,
	MatIconModule,
	MatListModule,
	MatCheckboxModule,
	MatDatepickerModule,
	MatNativeDateModule,
	MatSnackBarModule,
	MatTabsModule,
	MatRadioModule,
	MatPaginatorModule,
	MatTableModule,
	MatSelectModule,
	MatStepperModule,
	MatProgressSpinnerModule,
	MatFormFieldModule
]
