export * from './compliance.enums';
export * from './subscriptions.enums';

export enum AddressType {
	POSTAL_ADDRESS = 'Postal Address',
	PHYSICAL_ADDRESS = 'Physical Address',
}

export enum Role {
	WEZA_USER = 'WEZA_USER',
	WEZA_SYSTEM_ADMIN = 'WEZA_SYSTEM_ADMIN',
	WEZA_SUPERVISOR = 'WEZA_SUPERVISOR',
	WEZA_MANAGER = 'WEZA_MANAGER',
}

export enum Collection {
	BILLING = 'billing',
	USERS = 'users',
	WALLETS = 'wallets',
	EMPLOYEES = 'employees',
	SUBSCRIPTIONS = 'subscriptions',
	FILE_UPLOADS = 'file-uploads',
	COMPLIANCE = 'compliance',
	BILLING_SETTINGS = 'billing-settings',
	AUDIT_LOGS = 'audit-logs',
}

export enum Province {
	KWAZULU_NATAL = 'KwaZulu-Natal',
	EASTERN_CAPE = 'Eastern Cape',
	WESTERN_CAPE = 'Western Cape',
	NORTHERN_CAPE = 'Northern Cape',
	NORTH_WEST = 'North West',
	FREE_STATE = 'Free State',
	GAUTENG = 'Gauteng',
	MPUMALANGA = 'Mpumalanga',
	LIMPOMPO = 'Limpopo',
}

export enum BillType {
	QUOTE = 'QUOTATION',
	INVOICE = 'TAX INVOICE',
}

export enum DocumentCategory {
	PROOF_OF_PAYMENT = 'PROOF_OF_PAYMENT',
	ID_DOCUMENT = 'ID_DOCUMENT',
	TAX_CLEARANCE_CERTIFICATE = 'TAX_CLEARANCE_CERTIFICATE',
	COMPLIANCE_RESULT = 'COMPLIANCE_RESULT',
	COMPANY_LOGO = 'COMPANY_LOGO',
}

export enum WezaPlatform {
	INVOICING = 'INVOICING',
	COMPLIANCE = 'COMPLIANCE',
}

export enum PlatformDomain {
	APPS = 'apps',
	ACCOUNTS = 'accounts',
	INVOICING = 'invoicing',
	COMPLIANCE = 'compliance',
	BACKOFFICE = 'backoffice',
	PAYROLL = 'payroll',
	LOCAL = 'localhost',
}

export enum PlatformName {
	APPS = 'Apps',
	ACCOUNTS = 'Accounts',
	INVOICING = 'Invoicing',
	COMPLIANCE = 'Compliance',
	BACKOFFICE = 'Backoffice',
	PAYROLL = 'Payroll',
}

export enum PlatformID {
	APPS = 1,
	APPS_LOCAL = 2200,
	ACCOUNTS = 2,
	ACCOUNTS_LOCAL = 3200,
	INVOICING = 3,
	INVOICING_LOCAL = 4200,
	COMPLIANCE = 4,
	COMPLIANCE_LOCAL = 5200,
	BACKOFFICE = 5,
	BACKOFFICE_LOCAL = 6200,
	PAYROLL = 6,
	PAYROLL_LOCAL = 7200,
}

export enum PlatformSubscriptions {
	INVOICING = 'Invoicing',
}

export enum WezaEvent {
	SEND_VERIFICATION = 'SEND_VERIFICATION',
}

export enum Status {
	PENDING = 'PENDING',
	PROCESSING = 'PROCESSING',
	COMPLETED = 'COMPLETED',
}

export enum DocumentStatus {
	PENDING_REVIEW = 'PENDING_REVIEW',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
	ADMIN_UPLOAD = 'ADMIN_UPLOAD',
}

export enum Citizenship {
	OTHER = 'Other',
	SA_CITIZEN = 'SA Citizen',
	PERMANENT_RESIDENT = 'Permanent Resident',
}

export enum AddressType {
	POSTAL = 'Postal Address',
	RESIDENTIAL = 'Residential Address',
}

export enum IdentityType {
	ID_NUMBER = 'ID_NUMBER',
	PASSPORT = 'PASSPORT',
}

export enum DocumentUploadType {
	INPUT_DOCUMENT = 'INPUT_DOCUMENT',
	RESULT_DOCUMENT = 'RESULT_DOCUMENT',
}

export enum DirectorType {
	NEW = 'NEW',
	EXISTING = 'EXISTING',
}

export enum CompanyType {
	PRIVATE_COMPANY = 'Private Company - (Pty Ltd)',
	NPC = 'Non-Profit Company - (NPC)',
	NPO = 'Non-Profit Organization - (NPO)',
	CO_OP = 'Co-operative - (CO-OP)',
}

export enum BankAccountType {
	Cheque = 'Cheque',
	Savings = 'Savings',
	Transmission = 'Transmission',
}

export enum InvoiceRecordType {
	PAYMENT = 'Payment',
	CREDIT_ALLOWED = 'Credit Allowance',
	DISCOUNT_GRANTED = 'Discount Granted',
	INTEREST_CHARGED = 'Interest Charged',
}

export enum AdminAction {
	ACTIVATE_SUBSCRIPTION = 'Activate Subscription',
	CANCEL_SUBSCRIPTION = 'Cancel Subscription',
	DUMMY_TRANSACTION = 'Create Dummy Transaction'
}

export enum VATType {
	NO_VAT = 'NO_VAT',
	VAT_INCLUSIVE = 'VAT_INCLUSIVE',
	VAT_EXCLUSIVE = 'VAT_EXCLUSIVE'
}
