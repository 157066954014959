import { Component, Inject } from '@angular/core';
import { ENVIRONMENT, Environment, MenuLink, PlatformName } from '@wezacommon/ng-models';
import { AuthCommonService } from '@wezaonline/common';

@Component({
	selector: 'apps-layout',
	templateUrl: './apps-layout.component.html',
})
export class AppsLayoutComponent {
	constructor(
		private readonly auth: AuthCommonService,
		@Inject(ENVIRONMENT) private env: Environment
	) {}

	platform = PlatformName.APPS;
	external: MenuLink[] = [
		{
			url: `${this.env.url.accounts}/profile/${this.auth.user?.userId}`,
			name: 'My Profile',
			icon: 'account_circle',
		},
	];

	async onSignOut() {
		this.auth.signOut();
	}
}
