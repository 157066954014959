<svg
	xmlns="http://www.w3.org/2000/svg"
	xmlns:xlink="http://www.w3.org/1999/xlink"
	aria-hidden="true"
	focusable="false"
	width="1.5em"
	height="1.5em"
	style="
		-ms-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	"
	preserveAspectRatio="xMidYMid meet"
	viewBox="0 0 17 17"
>
	<path d="M9 4L7 2H0v13h16V4H9zm-1 9.5L4.5 10H7V6h2v4h2.5L8 13.5z" fill="#626262" />
</svg>
