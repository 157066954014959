<h1 mat-dialog-title>Subscription Required</h1>
<div mat-dialog-content>
	<p>
		Get unlimited access to <strong>{{ platform }}</strong> services for only R105
		per month
	</p>
	<p>Click subscriptions to redirect to your profile and subscribe to {{platform}}</p>
</div>
<br />
<div class="d-flex flex-column flex-sm-row">
	<button class="btn-weza" (click)="onClose()">Close</button>
	&nbsp;
	<button class="btn-weza" [mat-dialog-close]="'subscribe'">Subscriptions</button>
</div>