<div class="container">
	<div class="flex-center">
		<div class="width">
			<div class="flex-center">
				<a routerLink="/">
					<img [src]="logo" title="Weza Online" alt="" />
				</a>
			</div>
		</div>
		<br />
		<div class="width">
			<div class="title flex-center">Reset Password</div>
			<br />
			<ng-container *ngIf="submitted === false; else done">
				<form [formGroup]="form">
					<div class="row">
						<div class="col-sm">
							<label>
								Enter your email address we will send a link to reset your password.
							</label>
							<input type="text" class="form-control" formControlName="email" />
							<wezacommon-form-error [key]="'email'" [errors]="form.errors">
							</wezacommon-form-error>
						</div>
					</div>
				</form>
			</ng-container>
			<ng-template #done>
				<div class="flex-center">
					Check your email for instructions on resetting your password.
				</div>
				<br />
			</ng-template>
			<br />
			<div *ngIf="!submitted" class="footer d-grid gap-2">
				<button
					class="btn"
					[disabled]="form.invalid"
					(click)="submit(form.value)"
				>
					Submit
				</button>
			</div>
		</div>
		<br />
		<div class="width transparent">
			<div class="footer flex-center">
				<p>
					Go back to
					<a [href]="signIn"> Sign in </a>
				</p>
			</div>
		</div>
	</div>
</div>
