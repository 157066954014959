import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
	SignInResponse,
	SignUpResponse,
	ENVIRONMENT,
	EmployeeRequest,
	Environment,
	SignUpRequest,
	VerifyUserRequest,
} from '@wezacommon/ng-models';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthApiService {
	constructor(private readonly http: HttpClient, @Inject(ENVIRONMENT) private env: Environment) {}

	async signIn(email: string, password: string): Promise<SignInResponse> {
		const body = { email, password };
		const url = `${this.env.url.baseUrl}/accounts/signin`;

		return firstValueFrom(this.http.post<SignInResponse>(url, body));
	}

	async signUp(request: SignUpRequest): Promise<SignUpResponse> {
		const url = `${this.env.url.baseUrl}/accounts/signup`;
		return firstValueFrom(this.http.post<SignUpResponse>(url, request));
	}

	async refresh(refreshToken: string): Promise<SignInResponse> {
		const body = { refreshToken };
		const url = `${this.env.url.baseUrl}/accounts/refresh`;

		return firstValueFrom(this.http.post<SignInResponse>(url, body));
	}

	async employeeSignUp(employee: EmployeeRequest, idToken: string): Promise<SignUpResponse> {
		const httpOptions = {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + idToken,
			}),
		};
		const url = `${this.env.url.baseUrl}/accounts/employee`;

		return firstValueFrom(this.http.post<SignUpResponse>(url, employee, httpOptions));
	}

	async verifyUser(request: VerifyUserRequest): Promise<void> {
		const httpOptions = {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + request.token,
			}),
		};
		const url = `${this.env.url.baseUrl}/accounts/verify`;
		await firstValueFrom(this.http.post(url, request, httpOptions));
	}
}
