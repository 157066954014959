import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
	ProgressBarComponent,
	TransactionCategoriesComponent,
	TransactionHeaderComponent,
	TransactionTitleComponent,
} from './components';
import { PageNotFoundComponent } from './containers';
import {
	CancelIconComponentComponent,
	DeleteIconComponentComponent,
	DownloadIconComponentComponent,
	FileIconComponentComponent,
} from './icons';
import { MaterialDesign } from './material-design';
import { JoinPipe, RoundPipe, UrlParserPipe, UrlTitleCasePipe } from './pipes';
import { UiCommonModule } from '@wezaonline/common';

@NgModule({
	declarations: [
		TransactionTitleComponent,
		TransactionHeaderComponent,
		TransactionCategoriesComponent,
		JoinPipe,
		RoundPipe,
		UrlParserPipe,
		UrlTitleCasePipe,
		DeleteIconComponentComponent,
		ProgressBarComponent,
		FileIconComponentComponent,
		CancelIconComponentComponent,
		DownloadIconComponentComponent,
		PageNotFoundComponent,
	],
	imports: [
		...MaterialDesign,
		RouterModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		UiCommonModule,
	],
	exports: [
		TransactionTitleComponent,
		TransactionHeaderComponent,
		TransactionCategoriesComponent,
		CancelIconComponentComponent,
		JoinPipe,
		RoundPipe,
		UrlParserPipe,
		UrlTitleCasePipe,
		PageNotFoundComponent,
	],
})
export class CommonsModule {}
