import {
	CompliancePrefix,
	ComplianceTitle,
	ComplianceType,
	ComplianceUrlPath,
	DocumentStatus,
	Role,
} from './enums';
import { DocumentsRequired } from './types';

export const AppUrl = {
	SIGN_UP: 'signup',
	SIGN_IN: 'signin',
	DASHBOARD: 'dashboard',
	CREATE: 'create',
	DETAILS: 'details',
	EDIT: 'edit',
	BANK_ACCOUNTS: 'bank-accounts',
	COMPANIES: 'companies',
	CUSTOMERS: 'customers',
	BILLING: 'billing',
	BILLING_QUOTES: 'quotes',
	BILLING_PAID: 'paid',
	BILLING_DUE: 'due',
	USERS: 'users',
	USER: 'user',
	ADMIN: 'admin',
	ACCESS_DENIED: 'access-denied',
	SUBSCRIPTIONS: 'subscriptions',
	USER_SUBSCRIPTION: 'subscription',
	EMPLOYEES: 'employees',
	PENDING: 'pending',
	PENDING_RESUBSCRIPTION: 'pending-resubscription',
	CANCELLING: 'cancelling',
	ACTIVE: 'active',
	SEARCH: 'search',
	PROFILE: 'profile',
	RESET_PASSWORD: 'reset-password',
	DOCUMENTS: 'documents',
	ABOUT: 'about',
	CONTACT: 'contact',
	PAYMENT: 'payment',
	VERIFY_EMAIL: 'verify-email',
	FORGOT_PASSWORD: 'forgot-password',
	SETTINGS: 'settings',
	Compliance: {
		BASE_PATH: 'compliance',
		COMPANY_REGISTRATION: 'company-registration',
		TAX_CLEARANCE: 'tax-clearance',
		AMEND_COMPANY_NAME: 'amend-company-name',
		AMEND_COMPANY_ADDRESS: 'amend-company-address',
		VAT_REGISTRATION: 'vat-registration',
		CSD_REGISTRATION: 'csd-registration',
		LGS_REGISTRATION: 'lgs-registration',
		BCCCI_REGISTRATION: 'bccci-registration',
		CIDB_REGISTRATION: 'cidb-registration',
		BBBEE_REGISTRATION: 'bee-registration',
		IMPORT_EXPORT_CERTIFICATE: 'import-export-certificate',
		AMEND_COMPANY_DIRECTOR: 'amend-company-director',
		ADD_COMPANY_DIRECTOR: 'add-company-director',
		RESULTS: 'results',
		RESULT_CAPTURE: 'result-capture',
	},
	CONSULTANTS: 'consultants',
	AUDIT_LOGS: 'audit-logs',
};

export const AppRegex = {
	NO_LEADING_ZERO_DIGITS: /^[1-9][0-9]*$/,
	ANY_DIGITS: /^\d{1,}$/,
	TEN_DIGITS: /^\d{10}$/,
	THIRTEEN_DIGITS: /^\d{13}$/,
	FLOATS: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
	WEBSITE:
		/[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
};

export const AppKey = {
	USER: 'user',
};

export const RolesMap: Record<Role, string> = {
	WEZA_MANAGER: 'MANAGER',
	WEZA_SUPERVISOR: 'SUPERVISOR',
	WEZA_SYSTEM_ADMIN: 'SYSTEM ADMIN',
	WEZA_USER: 'USER',
};

export const Constants = {
	USER: 'user',
};

export const LocalCache = {
	AUTH_USER: 'USER',
	AUTH_EMPLOYEE: 'EMPLOYEE',
};

export const DocumentStatusDescription: Record<DocumentStatus, string> = {
	PENDING_REVIEW: 'Pending Review',
	APPROVED: 'Document Approved',
	REJECTED: 'Document Rejected',
	ADMIN_UPLOAD: 'Uploaded by Admin',
};

/**
 * List of dangerous extensions
 * @see https://www.file-extensions.org/filetype/extension/name/dangerous-malicious-files
 */
export const extensionBlackList = [
	'dll',
	'exe',
	'sys',
	'swf',
	'jar',
	'gzquar',
	'lnk',
	'zix',
	'js',
	'scr',
	'bat',
	'ws',
	'bin',
	'aru',
	'ozd',
	'ocx',
	'com',
	'vbs',
	'wmf',
	'class',
	'drv',
	'chm',
	'shs',
	'pgm',
	'dev',
	'xlm',
	'vba',
	'pif',
	'0_full_0_tgod_signed',
	'boo',
	'xnxx',
	'vbe',
	'vxd',
	'tps',
	'tsa',
	'sop',
	'pcx',
	'hlp',
	'386',
	'vb',
	'bkd',
	'rhk',
	'exe1',
	'cih',
	'lik',
	'osa',
	'vbx',
	'dyz',
	'exe_renamed',
	'kcd',
	'wsc',
	'dyv',
	's7p',
	'dlb',
	'dom',
	'dxz',
	'php3',
	'mfu',
	'mjg',
	'hlw',
	'cla',
	'rsc_tmp',
	'bup',
	'mjz',
	'upa',
	'bhx',
	'mcq',
	'dli',
	'fnr',
	'xir',
	'txs',
	'xlv',
	'xdu',
	'bxz',
	'cxq',
	'ska',
	'wsh',
	'wlpginstall',
	'cfxxe',
	'tti',
	'vexe',
	'qrn',
	'dllx',
	'fag',
	'smtmp',
	'xtbl',
	'tko',
	'ceo',
	'uzy',
	'scr',
	'oar',
	'bll',
	'plc',
	'spam',
	'ssy',
	'dbd',
	'smm',
	'ce0',
	'zvz',
	'cc',
	'blf',
	'ctbl',
	'iws',
	'vzr',
	'nls',
	'hsq',
	'lkh',
	'aepl',
	'rna',
	'let',
	'hts',
	'aut',
	'delf',
	'buk',
	'fuj',
	'atm',
	'ezt',
	'fjl',
	'bmw',
	'dx',
	'iva',
	'bps',
	'pid',
	'cyw',
	'capxml',
	'bqf',
	'pr',
	'qit',
	'xnt',
	'lpaq5',
	'lok',
];

export const UserEvents = {
	REMOVE_DIRECTOR: 'remove-director',
	DROP: 'drop',
	DRAG_OVER: 'dragover',
	DRAG_LEAVE: 'dragleave',
};

export const PriceList: Record<ComplianceType, number> = {
	COMPANY_REGISTRATION: 750,
	TAX_CLEARANCE: 350,
	AMEND_COMPANY_NAME: 550,
	AMEND_COMPANY_ADDRESS: 300,
	VAT_REGISTRATION: 750,
	CSD_REGISTRATION: 300,
	LGS_REGISTRATION: 1100,
	BCCCI_REGISTRATION: 1100,
	CIDB_REGISTRATION: 950,
	BBBEE_REGISTRATION: 350,
	IMPORT_EXPORT_CERTIFICATE: 1200,
	AMEND_COMPANY_DIRECTOR: 450,
	ADD_COMPANY_DIRECTOR: 450,
};

export const ComplianceTypeToPathMap: Record<ComplianceType, ComplianceUrlPath> = {
	COMPANY_REGISTRATION: ComplianceUrlPath.COMPANY_REGISTRATION,
	TAX_CLEARANCE: ComplianceUrlPath.TAX_CLEARANCE,
	AMEND_COMPANY_NAME: ComplianceUrlPath.AMEND_COMPANY_NAME,
	AMEND_COMPANY_ADDRESS: ComplianceUrlPath.AMEND_COMPANY_ADDRESS,
	VAT_REGISTRATION: ComplianceUrlPath.VAT_REGISTRATION,
	CSD_REGISTRATION: ComplianceUrlPath.CSD_REGISTRATION,
	LGS_REGISTRATION: ComplianceUrlPath.LGS_REGISTRATION,
	BCCCI_REGISTRATION: ComplianceUrlPath.BCCCI_REGISTRATION,
	CIDB_REGISTRATION: ComplianceUrlPath.CIDB_REGISTRATION,
	BBBEE_REGISTRATION: ComplianceUrlPath.BBBEE_REGISTRATION,
	IMPORT_EXPORT_CERTIFICATE: ComplianceUrlPath.IMPORT_EXPORT_CERTIFICATE,
	AMEND_COMPANY_DIRECTOR: ComplianceUrlPath.AMEND_COMPANY_DIRECTOR,
	ADD_COMPANY_DIRECTOR: ComplianceUrlPath.ADD_COMPANY_DIRECTOR,
};

export const ComplianceTitleTypeMap: Record<ComplianceType, ComplianceTitle> = {
	COMPANY_REGISTRATION: ComplianceTitle.COMPANY_REGISTRATION,
	TAX_CLEARANCE: ComplianceTitle.TAX_CLEARANCE,
	AMEND_COMPANY_NAME: ComplianceTitle.AMEND_COMPANY_NAME,
	AMEND_COMPANY_ADDRESS: ComplianceTitle.AMEND_COMPANY_ADDRESS,
	AMEND_COMPANY_DIRECTOR: ComplianceTitle.AMEND_COMPANY_DIRECTOR,
	VAT_REGISTRATION: ComplianceTitle.VAT_REGISTRATION,
	CSD_REGISTRATION: ComplianceTitle.CSD_REGISTRATION,
	LGS_REGISTRATION: ComplianceTitle.LGS_REGISTRATION,
	BCCCI_REGISTRATION: ComplianceTitle.BCCCI_REGISTRATION,
	CIDB_REGISTRATION: ComplianceTitle.CIDB_REGISTRATION,
	BBBEE_REGISTRATION: ComplianceTitle.BBBEE_REGISTRATION,
	IMPORT_EXPORT_CERTIFICATE: ComplianceTitle.IMPORT_EXPORT_CERTIFICATE,
	ADD_COMPANY_DIRECTOR: ComplianceTitle.ADD_COMPANY_DIRECTOR,
};

export const ComplianceTitleServiceLookup: Record<ComplianceUrlPath, ComplianceTitle> = {
	'company-registration': ComplianceTitle.COMPANY_REGISTRATION,
	'tax-clearance': ComplianceTitle.TAX_CLEARANCE,
	'amend-company-name': ComplianceTitle.AMEND_COMPANY_NAME,
	'amend-company-address': ComplianceTitle.AMEND_COMPANY_ADDRESS,
	'vat-registration': ComplianceTitle.VAT_REGISTRATION,
	'csd-registration': ComplianceTitle.CSD_REGISTRATION,
	'lgs-registration': ComplianceTitle.LGS_REGISTRATION,
	'bccci-registration': ComplianceTitle.BCCCI_REGISTRATION,
	'cidb-registration': ComplianceTitle.CIDB_REGISTRATION,
	'bee-registration': ComplianceTitle.BBBEE_REGISTRATION,
	'import-export-certificate': ComplianceTitle.IMPORT_EXPORT_CERTIFICATE,
	'amend-company-director': ComplianceTitle.AMEND_COMPANY_DIRECTOR,
	'add-company-director': ComplianceTitle.ADD_COMPANY_DIRECTOR,
};

export const ComplianceTypeMap: Record<ComplianceType, string> = {
	ADD_COMPANY_DIRECTOR: 'Add Company Director',
	AMEND_COMPANY_ADDRESS: 'Amend Company Address',
	AMEND_COMPANY_DIRECTOR: 'Amend Company Director',
	AMEND_COMPANY_NAME: 'Amend Company Name',
	BBBEE_REGISTRATION: 'BBBEE Registration',
	BCCCI_REGISTRATION: 'BCCCI Registration',
	CIDB_REGISTRATION: 'CIDB Registration',
	COMPANY_REGISTRATION: 'Company Registration',
	CSD_REGISTRATION: 'CSD Registration',
	IMPORT_EXPORT_CERTIFICATE: 'Import Export Certificate',
	LGS_REGISTRATION: 'LGS Registration',
	TAX_CLEARANCE: 'Tax Clearance',
	VAT_REGISTRATION: 'VAT Registration',
};

export const RequiredDocumentsMap: Record<ComplianceType, DocumentsRequired> = {
	COMPANY_REGISTRATION: {
		proofOfPayment: true,
		identityDocument: true,
		taxClearanceCertificate: false,
	},
	TAX_CLEARANCE: {
		proofOfPayment: true,
		identityDocument: true,
		taxClearanceCertificate: false,
	},
	AMEND_COMPANY_NAME: {
		proofOfPayment: true,
		identityDocument: false,
		taxClearanceCertificate: false,
	},
	AMEND_COMPANY_ADDRESS: {
		proofOfPayment: true,
		identityDocument: false,
		taxClearanceCertificate: false,
	},
	VAT_REGISTRATION: {
		proofOfPayment: true,
		identityDocument: true,
		taxClearanceCertificate: false,
	},
	CSD_REGISTRATION: {
		proofOfPayment: true,
		identityDocument: false,
		taxClearanceCertificate: false,
	},
	LGS_REGISTRATION: {
		proofOfPayment: true,
		identityDocument: false,
		taxClearanceCertificate: false,
	},
	BCCCI_REGISTRATION: {
		proofOfPayment: true,
		identityDocument: true,
		taxClearanceCertificate: false,
	},
	CIDB_REGISTRATION: {
		proofOfPayment: true,
		identityDocument: false,
		taxClearanceCertificate: false,
	},
	BBBEE_REGISTRATION: {
		proofOfPayment: true,
		identityDocument: false,
		taxClearanceCertificate: false,
	},
	IMPORT_EXPORT_CERTIFICATE: {
		proofOfPayment: true,
		identityDocument: true,
		taxClearanceCertificate: false,
	},
	AMEND_COMPANY_DIRECTOR: {
		proofOfPayment: true,
		identityDocument: true,
		taxClearanceCertificate: false,
	},
	ADD_COMPANY_DIRECTOR: {
		proofOfPayment: true,
		identityDocument: true,
		taxClearanceCertificate: false,
	},
};

export const CompliancePrefixMap: Record<ComplianceType, CompliancePrefix> = {
	COMPANY_REGISTRATION: CompliancePrefix.COMPANY_REGISTRATION,
	TAX_CLEARANCE: CompliancePrefix.TAX_CLEARANCE,
	AMEND_COMPANY_NAME: CompliancePrefix.AMEND_COMPANY_NAME,
	AMEND_COMPANY_ADDRESS: CompliancePrefix.AMEND_COMPANY_ADDRESS,
	VAT_REGISTRATION: CompliancePrefix.VAT_REGISTRATION,
	CSD_REGISTRATION: CompliancePrefix.CSD_REGISTRATION,
	LGS_REGISTRATION: CompliancePrefix.LGS_REGISTRATION,
	BCCCI_REGISTRATION: CompliancePrefix.BCCCI_REGISTRATION,
	CIDB_REGISTRATION: CompliancePrefix.CIDB_REGISTRATION,
	BBBEE_REGISTRATION: CompliancePrefix.BBBEE_REGISTRATION,
	IMPORT_EXPORT_CERTIFICATE: CompliancePrefix.IMPORT_EXPORT_CERTIFICATE,
	AMEND_COMPANY_DIRECTOR: CompliancePrefix.AMEND_COMPANY_DIRECTOR,
	ADD_COMPANY_DIRECTOR: CompliancePrefix.ADD_COMPANY_DIRECTOR,
};
