<h1 mat-dialog-title>Account Verification Required</h1>
<div mat-dialog-content>
	<p>
		Please verify by following a link sent to <strong>{{ email }}.</strong>
		<br />
		Check your spam or junk folder if you don't see the email in your inbox.
	</p>
	<p>
		If you no longer have access to this email account, please
		<a href="https://www.wezaonline.co.za/Contact.html" target="_blank">
			contact us.
		</a>
	</p>
</div>
<br />
<div class="d-flex flex-column flex-sm-row">
	<button class="btn-weza" (click)="onClose()">Close</button>
	&nbsp;
	<button class="btn-weza" [mat-dialog-close]="'resend'">Resend</button>
</div>
