import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
	name: "urlParser"
})
export class UrlParserPipe implements PipeTransform {
	transform(url: string): string {
		const pathRegex = /([a-z0-9_-]*[/]?)\S$/g
		const urlPaths = url.match(pathRegex)?.[0] || null

		if (!urlPaths) {
			return url
		}

		return urlPaths.replace(/[^A-Z0-9]/gi, " ")
	}
}
