import { Component, Input } from "@angular/core"

@Component({
	selector: "wezaonline-progress-bar",
	templateUrl: "./progress-bar.component.html",
	styleUrls: ["./progress-bar.component.scss"]
})
export class ProgressBarComponent {
	@Input() progress = 0
}
