import { inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivateChildFn,
	CanActivateFn,
	RouterStateSnapshot,
} from '@angular/router';
import { AccessService } from '../services';

export const adminActivate: CanActivateFn = (
	_route: ActivatedRouteSnapshot,
	_state: RouterStateSnapshot
) => {
	const access = inject(AccessService);
	return access.staffAccess;
};

export const adminActivateChild: CanActivateChildFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
) => adminActivate(route, state);
