import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuditLogRequest, ENVIRONMENT, Environment } from '@wezacommon/ng-models';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AdminAuditsApiService {
	private readonly adminAuditUrl: string;

	constructor(private readonly http: HttpClient, @Inject(ENVIRONMENT) private env: Environment) {
		this.adminAuditUrl = `${this.env.url.baseUrl}/audits/admin`;
	}

	async adminAudit(request: AuditLogRequest, idToken: string) {
		const httpOptions = this.createAuthHeader(idToken);
		await firstValueFrom(this.http.post(this.adminAuditUrl, request, httpOptions));
	}

	private createAuthHeader(idToken: string) {
		return {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + idToken,
			}),
		};
	}
}
