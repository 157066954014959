import { Component, Inject, Input } from '@angular/core';
import { ENVIRONMENT, Environment } from '@wezacommon/ng-models';

@Component({
	selector: 'wezaonline-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {
	@Input() goBackTo?: string;
	@Input() gotTackTitle = 'weza apps';

	constructor(@Inject(ENVIRONMENT) private env: Environment) {
		if (!this.goBackTo?.length) {
			this.goBackTo = this.env.url.apps.toLocaleLowerCase();
		}
	}
}
