import { Pipe, PipeTransform } from '@angular/core';
import { capitalize } from '@wezacommon/ng-utils';

@Pipe({ name: 'urlTitleCase' })
export class UrlTitleCasePipe implements PipeTransform {
	transform(input: string, splitter = '-'): string {
		if (typeof input !== 'string') {
			return input;
		}

		return input.split(splitter).reduce<string>((acc, current) => {
			if (acc.length) {
				return `${acc} ${capitalize(current)}`;
			}

			return capitalize(current);
		}, '');
	}
}
