export enum SubscriptionStatus {
	PENDING = 'PENDING',
	PENDING_RESUBSCRIPTION = 'PENDING_RESUBSCRIPTION',
	INACTIVE = 'INACTIVE',
	ACTIVE = 'ACTIVE',
	CANCEL_REQUESTED = 'CANCEL_REQUESTED',
	CANCELLED = 'CANCELLED',
}

export enum SubscriptionAdmin {
	ACTIVATE = 'activate',
	APPROVE_CANCEL = 'approve-cancel',
	TERMINATE = 'terminate',
}
