<div class="file-icon" style="width: 50px; height: 55px">
	<svg
		version="1.1"
		id="Capa_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 58 58"
		style="enable-background: new 0 0 58 58"
		xml:space="preserve"
	>
		<polygon style="fill: #edeada" points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 " />
		<g>
			<path
				style="fill: #cec9ae"
				d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z"
			/>
			<path
				style="fill: #cec9ae"
				d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z"
			/>
			<path
				style="fill: #cec9ae"
				d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z"
			/>
			<path
				style="fill: #cec9ae"
				d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z"
			/>
			<path
				style="fill: #cec9ae"
				d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z"
			/>
		</g>
		<polygon style="fill: #cec9ae" points="37.5,0 37.5,14 51.5,14 " />
	</svg>
</div>
