import { inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivateChildFn,
	CanActivateFn,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import { AppUrl } from '@wezacommon/ng-models';
import { AuthCommonService, LocalCacheService } from '../services';
import { isHttpError } from '@wezacommon/ng-utils';

export const authActivate: CanActivateFn = async (
	_route: ActivatedRouteSnapshot,
	_state: RouterStateSnapshot
) => {
	const router = inject(Router);
	const auth = inject(AuthCommonService);
	const cache = inject(LocalCacheService);

	if (auth.customer && !auth.customer.emailVerified && !_route.url.length) {
		// TODO: remove this refresh here makes app slow!!!
		const response = await auth.refreshToken(auth.customer.refreshToken);
		if (!isHttpError(response)) {
			cache.cacheAuthUser({ ...auth.customer, ...response });
			return true;
		} else {
			router.navigate([AppUrl.SIGN_IN]);
			return false;
		}
	}

	if (auth.isSignedIn || auth.isEmployeeSignedIn) {
		return true;
	} else {
		router.navigate([AppUrl.SIGN_IN]);
		return false;
	}
};

export const authActivateChild: CanActivateChildFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
) => authActivate(route, state);

export const employeeActivate: CanActivateFn = (
	_route: ActivatedRouteSnapshot,
	_state: RouterStateSnapshot
) => {
	const auth = inject(AuthCommonService);
	const router = inject(Router);
	if (!auth.isEmployeeSignedIn) {
		router.navigate([AppUrl.SIGN_IN]);
		return false;
	}

	return true;
};

export const employeeActivateChild: CanActivateChildFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
) => employeeActivate(route, state);
