import { CompliancePrefix, ComplianceType, PriceList } from '@wezacommon/ng-models';

function randomize(): number {
	return Math.floor(1000 + Math.random() * 9000);
}

export function generateTransactionRef(userRef: string, prefix: CompliancePrefix): string {
	return `${userRef}-${prefix}${randomize()}`;
}

// In future we wanna make this configurable
export function getCompliancePrice(type: ComplianceType) {
	return PriceList[type];
}
