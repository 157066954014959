import { Injectable } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { FormValidatorService } from './form-validator.service';

@Injectable({ providedIn: 'root' })
export class AuthFormService {
	private _signUpForm: FormGroup;
	private _signInForm: FormGroup;
	private _resetPasswordForm: FormGroup;

	constructor(private readonly validator: FormValidatorService) {
		this._signUpForm = this.createSignUpForm();
		this._signInForm = this.createSignInForm();
		this._resetPasswordForm = this.createResetPasswordForm();
	}

	get SignUpForm(): FormGroup {
		return this._signUpForm;
	}

	get SignInForm(): FormGroup {
		return this._signInForm;
	}

	get ResetPasswordForm(): FormGroup {
		return this._resetPasswordForm;
	}

	private createSignUpForm(): FormGroup {
		return new FormGroup(
			{
				email: this.createRequiredControl([
					Validators.required,
					Validators.email,
				]),
				password: this.createRequiredControl([Validators.required]),
				firstName: this.createRequiredControl([Validators.required]),
				lastName: this.createRequiredControl([Validators.required]),
				passwordMatch: this.createRequiredControl([Validators.required]),
			},
			{
				validators: [
					this.validator.userValidator(),
					this.validator.passwordValidator(),
				],
			}
		);
	}

	private createSignInForm(): FormGroup {
		return new FormGroup(
			{
				email: this.createRequiredControl([
					Validators.required,
					Validators.email,
				]),
				password: this.createRequiredControl([Validators.required]),
			},
			{
				validators: [
					this.validator.userValidator(),
					this.validator.passwordValidator(),
				],
			}
		);
	}

	private createResetPasswordForm(): FormGroup {
		return new FormGroup(
			{
				email: this.createRequiredControl([
					Validators.required,
					Validators.email,
				]),
			},
			{
				validators: [
					this.validator.userValidator(),
					this.validator.passwordValidator(),
				],
			}
		);
	}

	private createRequiredControl(validators: ValidatorFn[]) {
		return new FormControl('', validators);
	}
}
