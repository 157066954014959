import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, Environment } from '@wezacommon/ng-models';
import { groupLog } from '../common.utils';

@Injectable({ providedIn: 'root' })
export class UtilsService {
	constructor(@Inject(ENVIRONMENT) private env: Environment) {}

	get isLocalhost() {
		return this.env.platform.subDomain === 'localhost';
	}

	get platformId(): number {
		return this.env.platform.id;
	}

	devLogging(message: string, data: unknown) {
		if (!this.env.production) {
			groupLog(message, data);
		}
	}
}
