import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import {
	AtLeast,
	AuthUser,
	Collection,
	Compliance,
	CompliancePrefix,
	ComplianceType,
	PriceList,
	Status,
} from '@wezacommon/ng-models';
import { generateTransactionRef } from '@wezacommon/ng-utils';
import { UserService } from '@wezaonline/common';
import { Observable } from 'rxjs';

// TODO: update to use AngularFire v7 modular code
@Injectable({ providedIn: 'root' })
export class ComplianceService {
	private readonly basePath = Collection.COMPLIANCE;

	constructor(
		private readonly firestore: AngularFirestore,
		private readonly userService: UserService
	) {}

	/**
	 * Creates a new document
	 * @param data The document data
	 */
	async create<T>(data: Compliance<T, null>): Promise<void> {
		await this.collection.doc<Compliance<T, null>>(data.id).set(data);
	}

	/**
	 * Updates an existing document
	 * @param data The partial document data with uid to be updated
	 */
	async update<T, R>(data: AtLeast<Compliance<T, R>, 'id'>): Promise<void> {
		await this.collection.doc<Partial<Compliance<T, R>>>(data.id).update(data);
	}

	/**
	 * Delete a single document by given id
	 * @param uid The document unique identifier
	 */
	async deleteById(uid: string): Promise<void> {
		await this.collection.doc(uid).delete();
	}

	/**
	 * Streams document data by given id or resolves undefined if no document for given id
	 * @param uid The document unique identifier
	 */
	observeById<T, R>(uid: string): Observable<Compliance<T, R> | undefined> {
		return this.firestore.doc<Compliance<T, R>>(`${this.basePath}/${uid}`).valueChanges();
	}

	/**
	 * Get document collection stream
	 * @param queryFn The optional query function for special filtering
	 */
	observeCollection<T, R>(queryFn?: QueryFn): Observable<Compliance<T, R>[]> {
		return this.firestore
			.collection<Compliance<T, R>>(`${this.basePath}`, queryFn)
			.valueChanges();
	}

	createId(): string {
		return this.firestore.createId();
	}

	async init(user: AuthUser, type: ComplianceType, prefix: CompliancePrefix) {
		const now = Date.now();
		const price = PriceList[type];
		const balance = await this.userService.debitUserWallet(price, user.userId);

		return {
			price,
			balance,
			createdAt: now,
			modifiedAt: now,
			modifiedBy: 'None',
			result: { status: Status.PENDING },
			reference: generateTransactionRef(user.username, prefix),
		};
	}

	private get collection() {
		return this.firestore.collection(`${this.basePath}`);
	}
}
