import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UrlLink } from '@wezacommon/ng-models';
import { AccessService } from '../../services';

@Component({
	selector: 'wezacommon-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
	constructor(public readonly access: AccessService) {}

	@Input() gotoUrl?: UrlLink;
	@Input() heading?: string;
	@Input() navigationUrls?: Array<UrlLink>;

	@Output() create = new EventEmitter();

	get hasValidNavigation() {
		return !(
			this.navigationUrls &&
			this.navigationUrls?.length === 1 &&
			this.navigationUrls[0].title === this.heading
		);
	}

	async onCreate() {
		this.create.emit();
	}
}
