import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
	AuthUser,
	ENVIRONMENT,
	EmployeeRequest,
	Environment,
	Role,
	SignInRequest,
	SignUpRequest,
	SignUpResponse,
} from '@wezacommon/ng-models';
import { AuthApiService } from '../api/auth-api.service';
import { LocalCacheService } from './local-cache.service';

@Injectable({ providedIn: 'root' })
export class AuthCommonService {
	constructor(
		private readonly localCache: LocalCacheService,
		private readonly auth: AngularFireAuth,
		private readonly authApi: AuthApiService,
		@Inject(ENVIRONMENT) private env: Environment,
		@Inject(DOCUMENT) private document: Document
	) {}

	get isSignedIn(): boolean {
		return !!this.localCache.getAuthUser();
	}

	get isEmployeeSignedIn(): boolean {
		return !!this.localCache.getAuthEmployee();
	}

	get user(): AuthUser | undefined {
		return this.customer && this.customer.role === Role.WEZA_USER
			? this.customer
			: this.employee;
	}

	get customer(): AuthUser | undefined {
		const user = this.localCache.getAuthUser();
		if (!user?.length) return;
		return JSON.parse(user);
	}

	get employee(): AuthUser | undefined {
		const employee = this.localCache.getAuthEmployee();
		if (!employee?.length) return;

		return JSON.parse(employee);
	}

	async onResetPassword(email: string): Promise<void> {
		await this.auth.sendPasswordResetEmail(email);
	}

	async onUserSigIn({ email, password }: SignInRequest) {
		return this.authApi.signIn(email, password);
	}

	async onBackofficeSigIn({ email, password }: SignInRequest) {
		return this.authApi.signIn(email, password);
	}

	async refreshToken(refreshToken: string) {
		return this.authApi.refresh(refreshToken);
	}

	async onSignUp(signup: SignUpRequest): Promise<SignUpResponse> {
		return this.authApi.signUp(signup);
	}

	async onEmployeeSignUp(employee: EmployeeRequest, idToken: string): Promise<SignUpResponse> {
		return this.authApi.employeeSignUp(employee, idToken);
	}

	async sendVerification(email: string, token: string) {
		const redirect = this.env.platform.url;
		return this.authApi.verifyUser({ redirect, email, token });
	}

	async signOut(): Promise<void> {
		await this.auth.signOut();
		this.clearUsers();
		this.goToApps();
	}

	async onEmployeeSignOut(): Promise<void> {
		await this.auth.signOut();
		this.localCache.deleteAuthEmployee();
	}

	goToApps() {
		const wezaApps = `${this.env.url.apps}`;
		this.document.location.href = wezaApps;
	}

	clearUsers() {
		this.localCache.deleteAuthUser();
		this.localCache.deleteAuthEmployee();
	}
}
