<ol
	class="breadcrumb my-breadcrumb align-items-center"
	[ngClass]="{
		'my-breadcrumb': gotoUrl !== undefined && access.userAccess
	}"
>
	<ng-container *ngIf="hasValidNavigation">
		<li *ngFor="let url of navigationUrls" class="breadcrumb-item">
			<a routerLink="{{ url.link }}"> {{ url.title }} </a>
		</li>
	</ng-container>
	<li class="breadcrumb-item active">{{ heading }}</li>
	<li *ngIf="gotoUrl && access.userAccess" class="breadcrumb-item flex-fill">
		<a (click)="onCreate()" class="create btn">
			{{ gotoUrl.title }}
		</a>
	</li>
</ol>
