import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppUrl } from '@wezacommon/ng-models';
import { ComponentLifetime, UtilsService, createComponentLifetime } from '@wezacommon/ng-utils';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthCommonService, AuthFormService, SnackBarService } from '../../services';
import { UiCommonModule } from '../ui.module';

type FormField = {
	[key: string]: AbstractControl;
};

@Component({
	selector: 'wezacommon-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, CommonModule, UiCommonModule],
})
export class ResetPasswordComponent implements OnDestroy {
	signIn = AppUrl.SIGN_IN;
	form: FormGroup;
	logo = '../../../assets/images/logo-blue.png';
	public submitted = false;

	private componentLifetime: ComponentLifetime;

	constructor(
		private readonly loader: NgxUiLoaderService,
		private readonly formService: AuthFormService,
		private readonly util: UtilsService,
		private readonly auth: AuthCommonService,
		private readonly snackBar: SnackBarService
	) {
		this.form = this.formService.ResetPasswordForm;
		this.componentLifetime = createComponentLifetime();
	}

	ngOnDestroy(): void {
		this.componentLifetime.onDestroy();
	}

	async submit(value: FormField): Promise<void> {
		this.loader.start('submit');

		try {
			const email = value['email'] as unknown as string;
			await this.auth.onResetPassword(email.trim());
			this.loader.stop('submit');
			this.submitted = true;
		} catch (error) {
			const err = error as Error;

			let message = 'Unexpected error, try resetting password later later.';

			if (err.message.includes('auth/user-not-found')) {
				message = 'User not found. Sign up for Weza account instead.';
			}

			if (err.message.includes('auth/invalid-email')) {
				message = 'The email address is badly formatted.';
			}

			if (err.message.includes('(auth/timeout)')) {
				message = 'The operation has timed out.';
			}

			if (err.message.includes('auth/network-request-failed')) {
				message =
					'A network Error (such as timeout, interrupted connection or unreachable host) has occurred.';
			}

			this.snackBar.error(message);(message);
			this.loader.stop('submit');
		}
	}

}
