import { Routes } from '@angular/router';
import { PageNotFoundComponent } from '@wezaonline/commons';
import { DashboardComponent, AppsLayoutComponent } from '../pages';

export const appsRoutes: Routes = [
	{
		path: '',
		component: AppsLayoutComponent,
		children: [
			{
				path: '',
				component: DashboardComponent,
			},
		],
	},
	{
		path: '**',
		component: PageNotFoundComponent,
	},
];
