import { FormControl, ValidationErrors, Validators } from '@angular/forms';
import {
	DocumentCategory,
	FileSize,
	FileUnit,
	HttpError,
	PlatformDomain,
	PlatformID,
	WezaDocument,
	extensionBlackList,
} from '@wezacommon/ng-models';
import { Observable, OperatorFunction, filter, map, of } from 'rxjs';
import { v4 } from 'uuid';
import { ComponentLifetime } from './component-lifetime';

/**
 * @param subDomain The optional platform domain or apps if not provided.
 */
export function createWezaBusinessUrl(subDomain?: PlatformDomain) {
	if (subDomain) {
		return `https://${subDomain}.weza.business`;
	}

	return `https://${PlatformDomain.APPS}.weza.business`;
}

/**
 * @param subDomain The optional platform domain or apps if not provided.
 */
export function createWezaUrl(subDomain?: PlatformDomain) {
	if (subDomain) {
		return `https://${subDomain}.wezaonline.co.za`;
	}

	return `https://${PlatformDomain.APPS}.wezaonline.co.za`;
}

export function createPlatformUrl(subDomain: PlatformDomain, platformId: PlatformID) {
	if (subDomain === 'localhost') {
		return `http://${subDomain}:${platformId}`;
	}

	return `https://${subDomain}.wezaonline.co.za`;
}

export function createPlatformBusinessUrl(subDomain: PlatformDomain, platformId: PlatformID) {
	if (subDomain === 'localhost') {
		return `http://${subDomain}:${platformId}`;
	}

	return `https://${subDomain}.weza.business`;
}

export function sanitizeData<T, U = T>(data: T) {
	const clone = Object.assign({}, data) as U;

	for (const key in clone) {
		const value = clone[key];

		if (value === undefined || value === null) {
			delete clone[key];
			continue;
		}

		if (typeof value === 'string' && !value.trim().length) {
			delete clone[key];
			continue;
		}

		if (Array.isArray(value)) {
			const values = value.map((x) => sanitizeData(x));
			clone[key] = values as unknown as U[Extract<keyof U, string>];
		} else if (typeof value === 'object') {
			clone[key] = sanitizeData(value);
		}

		if (isEmptyObject(value) && !Array.isArray(value)) {
			delete clone[key];
			continue;
		}

		if (isFunction(value)) {
			delete clone[key];
			continue;
		}
	}

	return clone;
}

export function isEmptyObject<T>(value: T) {
	return value && typeof value === 'object' && !Object.keys(value).length;
}

export function isFunction<T>(x: T) {
	return x instanceof Function || typeof x === 'function';
}

export function createRequiredControl<T>(value: T, validators?: Validators) {
	if (validators) {
		return new FormControl(value, {
			nonNullable: true,
			...[Validators.required],
			...validators,
		});
	}

	return new FormControl(value, {
		nonNullable: true,
		...[Validators.required],
	});
}

export function createControl<T>(value: T, validators?: Validators, nonNullable?: boolean) {
	if (!nonNullable) {
		nonNullable = false;
	}

	if (validators) {
		return new FormControl(value, { nonNullable, ...validators });
	}
	return new FormControl(value, { nonNullable });
}

export function getErrorMessage(key: string, errors: ValidationErrors | null): string {
	return errors && errors[key] ? errors[key].message : '';
}

export function createComponentLifetime() {
	return new ComponentLifetime();
}

export function groupLog(message: string, data: unknown) {
	console.groupCollapsed(message);
	console.log(data);
	console.groupEnd();
}

export function isValidUrl(urlString: string) {
	try {
		return Boolean(new URL(urlString));
	} catch (error) {
		return false;
	}
}

export function getFileSize(bytes: number, decimals = 0): FileSize {
	if (bytes === 0) {
		return {
			value: 0,
			unit: 'Bytes',
		};
	}

	const k = 1024;
	const decimalPoint = decimals <= 0 ? 0 : decimals;
	const units: FileUnit[] = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return {
		value: parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPoint)),
		unit: units[i],
	};
}

export function isValidFileExtension(filename: string) {
	const extension = extractFileExtension(filename);
	return ['jpg', 'jpeg', 'png', 'pdf'].includes(extension);
}

export function extractFileExtension(filename: string): string {
	let extension: string | undefined;

	if (/[^.]+$/.exec(filename)) {
		extension = /[^.]+$/.exec(filename)?.find((x) => x.length > 0);
	}

	if (!extension) {
		throw new Error('Unexpected error, the file maybe corrupt or invalid');
	}

	if (extensionBlackList.includes(extension)) {
		throw new Error(`Dangerous file extension "${extension}" from filename "${filename}"`);
	}

	return extension;
}

export function isImageUrlValid(url: string): Promise<boolean> {
	try {
		return new Promise<boolean>((resolve) => {
			const img = new Image();
			img.src = url;

			if (img.complete) {
				resolve(true);
			} else {
				img.onload = () => {
					resolve(true);
				};

				img.onerror = () => {
					resolve(false);
				};
			}
		});
	} catch (error) {
		return Promise.resolve(false);
	}
}

export function createUuidV4() {
	return v4();
}

/**
 * Format bytes
 * @param bytes (File size in bytes)
 * @param decimals (Decimals point)
 */
export function formatBytes(bytes: number, decimals = 0): string {
	if (bytes === 0) {
		return '0 Bytes';
	}

	const k = 1024;
	const decimalPoint = decimals <= 0 ? 0 : decimals || 2;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPoint))} ${sizes[i]}`;
}

export function calculatePercentage(value: number, total: number): number {
	if (total && total > 0) {
		const percentage = (value / total) * 100;
		return Math.round(percentage);
	}

	return 0;
}

export function filterDocuments(
	document$: Observable<WezaDocument[]>,
	category: DocumentCategory
): Observable<WezaDocument[]> {
	const docs = document$?.pipe(map((docs) => docs.filter((doc) => doc.category === category)));
	return docs || of([]);
}

export function isHttpError(response: unknown): response is HttpError {
	return (response as HttpError)?.errorCode > 0;
}

export function filterNullable<T>(): OperatorFunction<T | undefined, T> {
	return (source: Observable<T | undefined>) =>
		source.pipe(
			filter<T | undefined, T>(function (val: T | undefined): val is T {
				return val !== undefined;
			})
		);
}

export const capitalize = (str: string) => str.slice(0, 1).toUpperCase() + str.slice(1);
