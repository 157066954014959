import { Subject } from 'rxjs';

export class ComponentLifetime {
	private _componentDestructions$ = new Subject<void>();
	isDestroyed$ = this._componentDestructions$.asObservable();

	onDestroy() {
		this._componentDestructions$.next();
	}
}
