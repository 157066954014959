import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'wezacommon-subscription',
	templateUrl: './subscription-dialog.component.html',
	styleUrls: ['./subscription-dialog.component.scss'],
	standalone: true,
	imports: [MatDialogModule],
})
export class SubscriptionDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<SubscriptionDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public platform: string
	) {}

	onClose(): void {
		this.dialogRef.close();
	}
}
