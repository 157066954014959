import { Component, Input } from "@angular/core"

@Component({
	selector: "wezaonline-transaction-header",
	templateUrl: "./transaction-header.component.html",
	styleUrls: ["./transaction-header.component.scss"]
})
export class TransactionHeaderComponent {
	@Input() heading: string | undefined
	@Input() secondUrl: string | undefined
}
