import { AbstractControl, ValidationErrors } from '@angular/forms';

export class FormValidator {
	/**
	 * Validates the email address and put results in errors object.
	 *
	 * @param formControl The form control containing fields to validate.
	 * @param errors The validation errors.
	 */
	public static validateEmail(
		formControl: AbstractControl,
		errors: ValidationErrors
	): void {
		const email = formControl.get('email');
		if (email && email.touched && email.errors && email.errors['email']) {
			errors['email'] = {
				message: 'Email address is not valid.',
			};
		}

		if (this.isRequired(email)) {
			errors['email'] = {
				message: 'Email address is required.',
			};
		}
	}

	/**
	 * Validates ID number length form control input.
	 *
	 * @param form The form control containing fields to validate.
	 * @param errors The validation errors.
	 */
	public static validateIdNumber(
		form: AbstractControl,
		errors: ValidationErrors
	): void {
		const number = form.get('number');
		if (number?.touched && number?.errors?.['pattern']) {
			errors['idNumber'] = {
				message: 'Identity number is not valid.',
			};
		}
	}

	/**
	 * Validates passport number.
	 *
	 * @param formControl The form control containing fields to validate.
	 * @param errors The validation errors.
	 */
	public static validatePassportNumber(
		formControl: AbstractControl,
		errors: ValidationErrors
	): void {
		const passport = formControl.get('passport');

		if (this.isRequired(passport)) {
			errors['passport'] = {
				message: 'Passport field is required.',
			};
		}
	}

	/**
	 * Validates cellphone number pattern and make sure it's required.
	 *
	 * @param formControl The form control containing fields to validate.
	 * @param errors The validation errors.
	 */
	public static validateCellNumber(
		formControl: AbstractControl,
		errors: ValidationErrors
	): void {
		const cellphone = formControl.get('cellphone');
		if (cellphone?.touched && cellphone?.errors?.['pattern']) {
			errors['cellphone'] = {
				message: 'Cellphone number must contain exactly 10 digits.',
			};
		}

		if (this.isRequired(cellphone)) {
			errors['cellphone'] = {
				message: 'Cellphone number is required.',
			};
		}
	}

	/**
	 * Validates telephone number pattern.
	 *
	 * @param formControl The form control containing fields to validate.
	 * @param errors The validation errors.
	 */
	public static validateTelephoneNumber(
		formControl: AbstractControl,
		errors: ValidationErrors
	): void {
		const telephone = formControl.get('telephone');
		if (telephone?.touched && telephone?.errors?.['pattern']) {
			errors['telephone'] = {
				message: 'Telephone number must contain exactly 10 digits.',
			};
		}
	}

	/**
	 * Validates surname form field.
	 *
	 * @param form The form control containing fields to validate.
	 * @param errors The validation errors.
	 */
	public static validateSurname(
		form: AbstractControl,
		errors: ValidationErrors
	): void {
		const surname = form.get('surname');
		if (this.isRequired(surname)) {
			errors['surname'] = {
				message: 'Surname is required.',
			};
		}
	}

	/**
	 * Validates company type form field with error message.
	 *
	 * @param formControl The form control containing fields to validate.
	 * @param errors The validation errors.
	 */
	public static validateCompanyType(
		formControl: AbstractControl,
		errors: ValidationErrors
	): void {
		const companyType = formControl.get('companyType');

		if (this.isRequired(companyType)) {
			errors['companyType'] = {
				message: 'Company type is required.',
			};
		}
	}

	/**
	 * Validates first name(s) form field.
	 *
	 * @param form The form control containing fields to validate.
	 * @param errors The validation errors.
	 */
	public static validateFirstNames(
		form: AbstractControl,
		errors: ValidationErrors
	): void {
		const firstNames = form.get('firstNames');

		if (this.isRequired(firstNames)) {
			errors['firstNames'] = {
				message: 'Please provide your full names.',
			};
		}
	}

	/**
	 * Validates that the proposed name is provided.
	 *
	 * @param formControl The form control containing fields to validate.
	 * @param errors The validation errors.
	 */
	public static validateProposedName(
		formControl: AbstractControl,
		errors: ValidationErrors
	): void {
		const control = formControl.get('proposedName');

		if (control?.touched && control?.errors?.['pattern']) {
			errors['proposedName'] = {
				message: 'Special characters are not allowed.',
			};
		}

		if (this.isRequired(control)) {
			errors['proposedName'] = {
				message: 'Proposed company name is required.',
			};
		}
	}

	/**
	 * Validates city form field.
	 *
	 * @param form The form control containing fields to validate.
	 * @param errors The validation errors.
	 */
	public static validateCity(
		form: AbstractControl,
		errors: ValidationErrors
	): void {
		const city = form.get('city');
		if (this.isRequired(city)) {
			errors['city'] = {
				message: 'City field is required.',
			};
		}
	}

	/**
	 * Validates addressLine1 form field.
	 *
	 * @param form The form control containing fields to validate.
	 * @param errors The validation errors.
	 */
	public static validateAddressLine1(
		form: AbstractControl,
		errors: ValidationErrors
	): void {
		const addressLine1 = form.get('addressLine1');
		if (this.isRequired(addressLine1)) {
			errors['addressLine1'] = {
				message: 'Address Line1 field is required.',
			};
		}
	}

	/**
	 * Validates postalCode form field.
	 *
	 * @param form The form control containing fields to validate.
	 * @param errors The validation errors.
	 */
	public static validatePostalCode(
		form: AbstractControl,
		errors: ValidationErrors
	): void {
		const postalCode = form.get('postalCode');
		if (this.isRequired(postalCode)) {
			errors['postalCode'] = {
				message: 'Postal code field is required.',
			};
		}
	}

	public static validateRejectDocumentReview(
		formControl: AbstractControl,
		errors: ValidationErrors
	): void {
		const control = formControl.get('rejectDocument');

		if (this.isRequired(control)) {
			errors['rejectDocument'] = {
				message: 'Reason for rejecting the document is required.',
			};
		}
	}

	public static validateApprovePaymentDocumentReview(
		formControl: AbstractControl,
		errors: ValidationErrors
	): void {
		const control = formControl.get('approvePaymentDocument');
		if (isNaN(control?.value)) {
			errors['approvePaymentDocument'] = {
				message: 'Please enter a valid payment amount.',
			};
		}

		if (this.isRequired(control)) {
			errors['approvePaymentDocument'] = {
				message: 'Approved payment amount is required.',
			};
		}
	}

	public static validateApproveIdentityDocumentReview(
		formControl: AbstractControl,
		errors: ValidationErrors
	): void {
		const control = formControl.get('approveIdentityDocument');
		if (isNaN(control?.value)) {
			errors['approveIdentityDocument'] = {
				message: 'Please enter a valid number of directors.',
			};
		}

		if (this.isRequired(control)) {
			errors['approveIdentityDocument'] = {
				message: 'The number of directors is required.',
			};
		}
	}

	private static isRequired(control: AbstractControl | null): boolean {
		return (
			control &&
			!control.valid &&
			control.errors &&
			control.errors['required'] &&
			(control.touched || control.dirty)
		);
	}
}
