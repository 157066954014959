import { Component } from "@angular/core"
import { Router } from "@angular/router"

@Component({
	selector: "wezaonline-transaction-categories",
	templateUrl: "./transaction-categories.component.html",
	styleUrls: ["./transaction-categories.component.scss"]
})
export class TransactionCategoriesComponent {
	public activeLink: string | undefined
	public links = ["all", "pending", "completed"]

	constructor(private router: Router) {
		this._updateSelection()
	}

	private _updateSelection(): void {
		const activePathRegex = /([a-z0-9_-]*[/]?)\S$/g
		this.activeLink = this.router.routerState.snapshot.url.match(activePathRegex)?.[0]
	}
}
