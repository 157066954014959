import { Injectable } from '@angular/core';
import { Role } from '@wezacommon/ng-models';
import { AuthCommonService } from './auth-common.service';

@Injectable({ providedIn: 'root' })
export class AccessService {
	constructor(private readonly auth: AuthCommonService) {}

	get userAccess() {
		return this.auth.user?.role === Role.WEZA_USER;
	}

	get managerAccess() {
		return this.auth.employee?.role === Role.WEZA_MANAGER;
	}

	get supervisorAccess() {
		return this.auth.employee?.role === Role.WEZA_SUPERVISOR;
	}

	get adminAccess() {
		return this.auth.employee?.role === Role.WEZA_SYSTEM_ADMIN;
	}

	get staffAccess() {
		return this.adminAccess || this.managerAccess || this.supervisorAccess;
	}
}
