import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
	AdminSubscriptionRequest,
	ENVIRONMENT,
	Environment,
	SubscriptionAdmin,
	SubscriptionPlatform,
	UserSubscriptionRequest,
} from '@wezacommon/ng-models';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SubscriptionsApiService {
	private readonly userUrl: string;
	private readonly adminUrl: string;

	constructor(private readonly http: HttpClient, @Inject(ENVIRONMENT) private env: Environment) {
		this.userUrl = `${this.env.url.baseUrl}/subscriptions/user`;
		this.adminUrl = `${this.env.url.baseUrl}/subscriptions/admin`;
	}

	async userSubscribe(platform: SubscriptionPlatform, idToken: string): Promise<void> {
		const httpOptions = this.createAuthHeader(idToken);
		const request: UserSubscriptionRequest = { action: 'subscribe', platform };

		await firstValueFrom(this.http.post(this.userUrl, request, httpOptions));
	}

	async userCancel(platform: SubscriptionPlatform, idToken: string): Promise<void> {
		const httpOptions = this.createAuthHeader(idToken);
		const request: UserSubscriptionRequest = { action: 'cancel', platform };

		await firstValueFrom(this.http.post(this.userUrl, request, httpOptions));
	}

	async admin(
		platform: SubscriptionPlatform,
		idToken: string,
		userId: string,
		action: SubscriptionAdmin,
		reason?: string
	): Promise<void> {
		const httpOptions = this.createAuthHeader(idToken);
		const request: AdminSubscriptionRequest = {
			action,
			platform,
			userId,
			reason,
		};

		await firstValueFrom(this.http.post(this.adminUrl, request, httpOptions));
	}

	private createAuthHeader(idToken: string) {
		return {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + idToken,
			}),
		};
	}
}
