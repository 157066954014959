<br />
<div class="col-md-8 col-sm-6 col-xs-12 mx-auto">
	<ul class="cards">
		<li class="cards-item">
			<div class="card">
				<div class="card-content">
					<div class="card-title">Compliance</div>
					<br />
					<p>
						Compliance services caters for statutary enforced
						registration requirements for organisations wishing to trade
						within South African economic space. These are requirements
						stipulated by various South African Acts such as Companies
						Act, Labour Act, National Credit Act and other supporting
						regulations applicable as per individual organisation or
						business.
					</p>
					<div class="card-footer">
						<button
							mat-icon-button
							color="primary"
							title="Compliance"
							(click)="openCompliance()"
						>
							<mat-icon fontSet="material-icons-round">
								open_in_new
							</mat-icon>
							&nbsp;
							<span>Open</span>
						</button>
					</div>
				</div>
			</div>
		</li>
		<li class="cards-item">
			<div class="card">
				<div class="card-content">
					<div class="card-title">Billing</div>
					<br />
					<p>Invoicing and Quotation 360° Solution.</p>
					<div class="card-footer">
						<button
							mat-icon-button
							color="primary"
							title="Invoicing"
							(click)="openInvoicing()"
						>
							<mat-icon fontSet="material-icons-round">
								open_in_new
							</mat-icon>
							&nbsp;
							<span>Open</span>
						</button>
					</div>
				</div>
			</div>
		</li>
		<li class="cards-item">
			<div class="card">
				<div class="card-content">
					<div class="card-title">Payroll</div>
					<div class="card-title">(Coming Soon)</div>
					<br />
					<p>
						Vel esse et temporibus inventore alias assumenda. Repellat
						sit accusamus id sed est animi vel. Odit non ut reprehenderit
						consequatur sit aliquam. Aut aliquid consequatur
					</p>
				</div>
			</div>
		</li>
	</ul>
</div>
