import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ENVIRONMENT } from '@wezacommon/ng-models';
import { UiCommonModule } from '@wezaonline/common';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppsLayoutComponent, DashboardComponent } from './pages';
import { RoutingModule } from './routing/routing.module';

@NgModule({
	declarations: [AppComponent, AppsLayoutComponent, DashboardComponent],
	imports: [
		BrowserModule,
		RoutingModule,
		UiCommonModule,
		HttpClientModule,
		NgxUiLoaderModule,
		BrowserAnimationsModule,
		AngularFireModule.initializeApp(environment.firebase),
	],
	providers: [{ provide: ENVIRONMENT, useValue: environment }],
	bootstrap: [AppComponent],
})
export class AppModule {}
