import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn, QueryGroupFn } from '@angular/fire/compat/firestore';
import { trace } from '@angular/fire/compat/performance';
import { AtLeast, Collection, Identifier } from '@wezacommon/ng-models';
import { sanitizeData } from '@wezacommon/ng-utils';
import { Observable, map } from 'rxjs';

// TODO: update firestore and remove compat.
@Injectable({ providedIn: 'root' })
export class FirestoreService {
	constructor(private readonly firestore: AngularFirestore) {}

	get documentId() {
		return this.firestore.createId();
	}

	/**
	 * Creates a new document
	 *
	 * @param data The document data
	 * @param path Firestore collection path
	 */
	async create<T extends Identifier>(data: T, path: Collection): Promise<void> {
		if (!data?.id?.length) {
			data['id'] = this.documentId;
		}

		await this.collection<T>(path).doc<T>(data.id).set(sanitizeData(data));
	}

	/**
	 * Updates an existing document
	 * @param data The partial document data with uid to be updated
	 * @param path Firestore collection path
	 */
	async update<T extends Identifier>(data: AtLeast<T, 'id'>, path: Collection): Promise<void> {
		await this.collection<T>(path).doc<AtLeast<T, 'id'>>(data.id).update(sanitizeData(data));
	}

	/**
	 * Delete a single document by given id
	 * @param uid The document unique identifier
	 * @param path Firestore collection path
	 */
	async deleteById(uid: string, path: Collection): Promise<void> {
		await this.collection(path).doc(uid).delete();
	}

	/**
	 * Streams document data by given id or resolves undefined if no document for given id
	 * @param uid The document unique identifier
	 * @param path Firestore collection path
	 */
	getById$<T>(uid: string, path: Collection): Observable<T | undefined> {
		return this.firestore.doc<T>(`${path}/${uid}`).valueChanges();
	}

	/**
	 * Get document collection stream
	 * @param path Firestore collection path
	 * @param queryFn The optional query function for special filtering
	 */
	getCollection$<T>(path: Collection, queryFn?: QueryFn): Observable<T[]> {
		return this.collection<T>(path, queryFn).valueChanges();
	}

	/**
	 * Get document collection stream
	 * @param path Firestore collection path
	 * @param queryFn The optional query function for special filtering
	 */
	collection$<T>(path: Collection, queryFn?: QueryFn): Observable<T[]> {
		return this.collection<T>(path, queryFn)
			.snapshotChanges()
			.pipe(
				trace(path),
				map((action) =>
					action.map((change) => {
						return { ...change.payload.doc.data(), id: change.payload.doc.id };
					})
				)
			);
	}

	/**
	 * Get document collection group stream
	 * @param path Firestore collection path
	 * @param queryFn The optional query function for special filtering
	 */
	getCollectionGroup$<T>(path: Collection, queryFn?: QueryGroupFn<T>): Observable<T[]> {
		return this.firestore.collectionGroup<T>(path, queryFn).valueChanges();
	}

	private collection<T>(path: Collection | string, queryFn?: QueryFn) {
		return this.firestore.collection<T>(path, queryFn);
	}
}
