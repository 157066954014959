import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { AppUrl, ENVIRONMENT, Environment, MenuLink, PlatformName } from '@wezacommon/ng-models';
import { AccessService, AuthCommonService } from '../../services';

@Component({
	selector: 'wezacommon-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
	companiesUrl = AppUrl.COMPANIES;
	customersUrl = AppUrl.CUSTOMERS;
	bankAccountsUrl = AppUrl.BANK_ACCOUNTS;
	usersUrl = AppUrl.ADMIN;

	@Input() orderedMenu: MenuLink[] = [];
	@Input() externalMenu: MenuLink[] = [];
	@Input() platformName: PlatformName = PlatformName.APPS;

	@Output() signOut = new EventEmitter();

	constructor(
		private readonly auth: AuthCommonService,
		public readonly access: AccessService,
		@Inject(ENVIRONMENT) private env: Environment
	) {}

	get user() {
		return this.auth.user || this.auth.employee;
	}

	get platform() {
		return this.env.platform.name;
	}

	async onSignOut() {
		this.signOut.emit();
	}

	openLink(url: string) {
		window.open(url);
	}
}
