import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DialogMessage } from '@wezacommon/ng-models';

@Component({
	selector: 'wezacommon-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss'],
	standalone: true,
	imports: [MatDialogModule],
})
export class ConfirmDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<ConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogMessage
	) {}

	onCancel(): void {
		this.dialogRef.close();
	}
}
