<section class="header-main">
	<div class="d-flex">
		<div class="nav-logo p-2 mr-auto">
			<a routerLink="/" title="Weza Online">
				<img src="/assets/images/logo-white.png" alt="Weza Online" />
			</a>
		</div>
		<div *ngIf="user && platform !== 'Apps'" class="p-2">
			<button mat-button [matMenuTriggerFor]="menu">
				<span>{{ user.displayName }}</span>
				&nbsp;
				<mat-icon fontSet="material-icons-round">account_box</mat-icon>
			</button>
			<mat-menu #menu="matMenu">
				<ng-container *ngIf="access.userAccess">
					<button
						*ngFor="let menu of externalMenu"
						(click)="openLink(menu.url)"
						target="_blank"
						mat-menu-item
					>
						<mat-icon fontSet="material-icons-round">
							{{ menu.icon }}
						</mat-icon>
						<span>{{ menu.name }}</span>
					</button>
					<button
						*ngFor="let menu of orderedMenu"
						[routerLink]="[menu.url]"
						mat-menu-item
					>
						<mat-icon fontSet="material-icons-round">
							{{ menu.icon }}
						</mat-icon>
						<span>{{ menu.name }}</span>
					</button>
				</ng-container>
				<button mat-menu-item [routerLink]="[usersUrl]" *ngIf="access.staffAccess">
					<mat-icon fontSet="material-icons-round"> group </mat-icon>
					<span>Users</span>
				</button>
				<button (click)="onSignOut()" mat-menu-item>
					<mat-icon fontSet="material-icons-round">logout</mat-icon>
					<span>Sign Out</span>
				</button>
			</mat-menu>
		</div>

		<div *ngIf="platform === 'Apps'" class="p-2">
			<button mat-button (click)="openLink('https://wezaonline.co.za')">
				<span>Website</span>
				&nbsp;
				<mat-icon fontSet="material-icons-round">public</mat-icon>
			</button>
		</div>
	</div>
</section>
<div class="clear"></div>
