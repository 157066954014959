// Some need to go to config
export enum CompliancePrefix {
	COMPANY_REGISTRATION = 'CRG',
	TAX_CLEARANCE = 'TAX',
	IMPORT_EXPORT_CERTIFICATE = 'IEC',
	CIDB_REGISTRATION = 'CID',
	BBBEE_REGISTRATION = 'BEE',
	BCCCI_REGISTRATION = 'BCC',
	AMEND_COMPANY_NAME = 'ACN',
	AMEND_COMPANY_ADDRESS = 'ACA',
	AMEND_COMPANY_DIRECTOR = 'ACD',
	VAT_REGISTRATION = 'VAT',
	CSD_REGISTRATION = 'CSD',
	LGS_REGISTRATION = 'LGS',
	ADD_COMPANY_DIRECTOR = 'NDC',
}

export enum ComplianceType {
	COMPANY_REGISTRATION = 'COMPANY_REGISTRATION',
	TAX_CLEARANCE = 'TAX_CLEARANCE',
	AMEND_COMPANY_NAME = 'AMEND_COMPANY_NAME',
	AMEND_COMPANY_ADDRESS = 'AMEND_COMPANY_ADDRESS',
	VAT_REGISTRATION = 'VAT_REGISTRATION',
	CSD_REGISTRATION = 'CSD_REGISTRATION',
	LGS_REGISTRATION = 'LGS_REGISTRATION',
	BCCCI_REGISTRATION = 'BCCCI_REGISTRATION',
	CIDB_REGISTRATION = 'CIDB_REGISTRATION',
	BBBEE_REGISTRATION = 'BBBEE_REGISTRATION',
	IMPORT_EXPORT_CERTIFICATE = 'IMPORT_EXPORT_CERTIFICATE',
	AMEND_COMPANY_DIRECTOR = 'AMEND_COMPANY_DIRECTOR',
	ADD_COMPANY_DIRECTOR = 'ADD_COMPANY_DIRECTOR',
}

export enum ComplianceUrlPath {
	COMPANY_REGISTRATION = 'company-registration',
	TAX_CLEARANCE = 'tax-clearance',
	AMEND_COMPANY_NAME = 'amend-company-name',
	AMEND_COMPANY_ADDRESS = 'amend-company-address',
	VAT_REGISTRATION = 'vat-registration',
	CSD_REGISTRATION = 'csd-registration',
	LGS_REGISTRATION = 'lgs-registration',
	BCCCI_REGISTRATION = 'bccci-registration',
	CIDB_REGISTRATION = 'cidb-registration',
	BBBEE_REGISTRATION = 'bee-registration',
	IMPORT_EXPORT_CERTIFICATE = 'import-export-certificate',
	AMEND_COMPANY_DIRECTOR = 'amend-company-director',
	ADD_COMPANY_DIRECTOR = 'add-company-director',
}

export enum ComplianceTitle {
	COMPANY_REGISTRATION = 'Company Registrations',
	TAX_CLEARANCE = 'Tax Clearances',
	AMEND_COMPANY_NAME = 'Company Name Amendments',
	AMEND_COMPANY_ADDRESS = 'Company Address Amendments',
	VAT_REGISTRATION = 'VAT Registrations',
	CSD_REGISTRATION = 'CSD Registrations',
	LGS_REGISTRATION = 'LGS Registrations',
	BCCCI_REGISTRATION = 'BCCCI Registrations',
	CIDB_REGISTRATION = 'CIDB Registrations',
	BBBEE_REGISTRATION = 'BBBEE Registrations',
	IMPORT_EXPORT_CERTIFICATE = 'Export/Import Certificates',
	AMEND_COMPANY_DIRECTOR = 'Amend Company Directors',
	ADD_COMPANY_DIRECTOR = 'Add Company Directors',
}

export enum ComplianceResultType {
	AllDocsUploaded = 'AllDocsUploaded',
	AllDocsApproved = 'AllDocsApproved',
	TransactionCompleted = 'TransactionCompleted',
	PendingPayment = 'PendingPayment',
	LessDocsUploaded = 'LessDocsUploaded',
}
