import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Address, UserProfile } from '@wezacommon/ng-models';
import { FormValidatorService } from '@wezaonline/common';

@Injectable({ providedIn: 'root' })
export class ProfileFormService {
	constructor(private validator: FormValidatorService) {}

	createAddressForm(): UntypedFormGroup {
		return new UntypedFormGroup(
			{
				city: this._createRequiredControl(),
				province: this._createOptionalControl(),
				suburb: this._createOptionalControl(),
				addressLine1: this._createRequiredControl(),
				addressLine2: this._createOptionalControl(),
				postalCode: this._createRequiredControl(),
			},
			{
				validators: this.validator.addressValidator(),
			}
		);
	}

	createPersonalDetailsForm(email: string): UntypedFormGroup {
		const _10DigitsRegex = /^\d{10}$/;
		return new UntypedFormGroup(
			{
				firstName: this._createRequiredControl(),
				lastName: this._createOptionalControl(),
				email: this._createRequiredControl(email, true),
				phoneNumber: new UntypedFormControl('', [Validators.pattern(_10DigitsRegex)]),
			},
			{
				validators: this.validator.userValidator(),
			}
		);
	}

	editPersonalDetailsForm(details: UserProfile): UntypedFormGroup {
		const _10DigitsRegex = /^\d{10}$/;
		return new UntypedFormGroup(
			{
				firstName: this._createRequiredControl(details.firstName, true),
				lastName: this._createRequiredControl(details.lastName, true),
				email: this._createRequiredControl(details.email, true),
				phoneNumber: new UntypedFormControl(details?.phoneNumber, [
					Validators.pattern(_10DigitsRegex),
				]),
			},
			{
				validators: this.validator.userValidator(),
			}
		);
	}

	editAddressesForm(address: Address): UntypedFormGroup {
		return new UntypedFormGroup(
			{
				city: this._createRequiredControl(address.city),
				province: this._createOptionalControl(address?.province),
				suburb: this._createOptionalControl(address?.suburb),
				addressLine1: this._createRequiredControl(address.addressLine1),
				addressLine2: this._createOptionalControl(address?.addressLine2),
				postalCode: this._createRequiredControl(address.postalCode),
			},
			{
				validators: this.validator.addressValidator(),
			}
		);
	}

	createSubscriptionDetailsForm(): UntypedFormGroup {
		const _10DigitsRegex = /^\d{10}$/;
		return new UntypedFormGroup(
			{
				phoneNumber: new UntypedFormControl('', [
					Validators.pattern(_10DigitsRegex),
					Validators.required,
				]),
			},
			{
				validators: this.validator.subscriptionValidator(),
			}
		);
	}

	private _createOptionalControl(value?: string, disabled = false) {
		return new UntypedFormControl({ value, disabled });
	}

	private _createRequiredControl(value?: string, disabled = false) {
		return new UntypedFormControl({ value, disabled }, this._createRequiredValidator());
	}

	private _createRequiredValidator() {
		return [Validators.required];
	}
}
