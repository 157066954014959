import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'wezacommon-verify-email',
	templateUrl: './verify-email-dialog.component.html',
	styleUrls: ['./verify-email-dialog.component.scss'],
	standalone: true,
	imports: [MatDialogModule],
})
export class VerifyEmailDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<VerifyEmailDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public email: string
	) {}

	onClose(): void {
		this.dialogRef.close();
	}
}
