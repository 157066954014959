import { DocumentStatus, Status } from '@wezacommon/ng-models';

export const Categories = {
	ALL: 'all',
	PENDING: 'pending',
	COMPLETED: 'completed',
};

export const TransactionStatusDescription: Record<Status, string> = {
	COMPLETED: 'Transaction Completed',
	PROCESSING: 'Processing Transaction',
	PENDING: 'Pending',
};

export const DocumentStatusDescription: Record<DocumentStatus, string> = {
	PENDING_REVIEW: 'Pending Review',
	APPROVED: 'Document Approved',
	REJECTED: 'Document Rejected',
	ADMIN_UPLOAD: 'Uploaded by Admin',
};

export const UserEvents = {
	REMOVE_DIRECTOR: 'remove-director',
	DROP: 'drop',
	DRAG_OVER: 'dragover',
	DRAG_LEAVE: 'dragleave',
};
