<wezacommon-navbar></wezacommon-navbar>
<div class="container">
	<div class="flex-center">
		<h2 class="text-color">Page Not Found!</h2>
		<div class="transparent">
			<p class="text-color">
				Go to
				<a [href]="goBackTo"> {{ gotTackTitle }} </a>
			</p>
		</div>
	</div>
</div>
