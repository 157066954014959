import { Inject, Injectable } from '@angular/core';
import { AuthUser, ENVIRONMENT, Environment, LocalCache } from '@wezacommon/ng-models';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class LocalCacheService {
	private readonly wezaDomain;

	constructor(
		private readonly cookieService: CookieService,
		@Inject(ENVIRONMENT) private env: Environment
	) {
		this.wezaDomain = this.env.url.baseUrl.endsWith('red.weza.business')
			? 'wezaonline.co.za'
			: 'weza.business';
	}

	cacheAuthUser(user: AuthUser, key = LocalCache.AUTH_USER): void {
		const { subDomain } = this.env.platform;
		const domain = subDomain === 'localhost' ? undefined : `.${this.wezaDomain}`;
		const expireAt = new Date();

		expireAt.setSeconds(expireAt.getSeconds() + Number(user.expiresIn));
		this.cookieService.set(key, JSON.stringify(user), expireAt, '/', domain, true, 'Strict');
	}

	getAuthUser() {
		return this.cookieService.get(LocalCache.AUTH_USER);
	}

	getAuthEmployee() {
		return this.cookieService.get(LocalCache.AUTH_EMPLOYEE);
	}

	deleteAuthUser() {
		const { subDomain } = this.env.platform;
		const domain = subDomain === 'localhost' ? undefined : `.${this.wezaDomain}`;
		this.cookieService.delete(LocalCache.AUTH_USER, '/', domain, true, 'Strict');
	}

	deleteAuthEmployee() {
		const { subDomain } = this.env.platform;
		const domain = subDomain === 'localhost' ? undefined : `.${this.wezaDomain}`;
		this.cookieService.delete(LocalCache.AUTH_EMPLOYEE, '/', domain, true, 'Strict');
	}
}
