<nav class="m-t" mat-tab-nav-bar>
	<a
		mat-tab-link
		*ngFor="let link of links"
		(click)="activeLink = link"
		[active]="activeLink === link"
		[routerLink]="['../'+link]"
	>
		{{ link | titlecase }}
	</a>
</nav>
