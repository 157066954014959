import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class SnackBarService {
	constructor(private readonly snackBar: MatSnackBar) {}

	private configSuccess: MatSnackBarConfig = {
		panelClass: ['style-success'],
		verticalPosition: 'top',
		duration: 8000,
	};

	private configWarn: MatSnackBarConfig = {
		panelClass: ['style-warn'],
		verticalPosition: 'top',
		duration: 8000,
	};

	private configError: MatSnackBarConfig = {
		panelClass: ['style-error'],
		verticalPosition: 'top',
	};

	public success(message: string) {
		return this.snackBar.open(message, 'Close', this.configSuccess);
	}

	public error(message: string): void {
		this.snackBar.open(message, 'Close', this.configError);
	}

	public warn(message: string): void {
		this.snackBar.open(message, 'Close', this.configWarn);
	}
}
