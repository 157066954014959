import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
	name: "join"
})
export class JoinPipe implements PipeTransform {
	transform(input: unknown, separator = ", "): string | unknown {
		if (!Array.isArray(input)) {
			return input
		}

		return input.join(separator)
	}
}
