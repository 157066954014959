import { Injectable } from '@angular/core';
import { Collection, UserProfile, UserSubscriptions, Wallet } from '@wezacommon/ng-models';
import { Observable, firstValueFrom } from 'rxjs';
import { FirestoreService } from './firestore.service';

@Injectable({ providedIn: 'root' })
export class UserService {
	private readonly wallets = Collection.WALLETS;
	private readonly users = Collection.USERS;
	private readonly subscriptions = Collection.SUBSCRIPTIONS;

	constructor(private readonly db: FirestoreService) {}

	getUserProfile$(userId: string): Observable<UserProfile | undefined> {
		return this.db.getById$<UserProfile>(userId, this.users);
	}

	getUserWallet$(userId: string): Observable<Wallet | undefined> {
		return this.db.getById$<Wallet>(userId, this.wallets);
	}

	getUserSubscription$(userId: string): Observable<UserSubscriptions | undefined> {
		return this.db.getById$<UserSubscriptions>(userId, this.subscriptions);
	}

	async updateUserWallet(id: string, update: Partial<Wallet>) {
		await this.db.update({ id, ...update }, Collection.WALLETS);
	}

	async debitUserWallet(amount: number, userId: string) {
		const wallet = await firstValueFrom(this.getUserWallet$(userId));
		if (!wallet) {
			throw new Error(`No wallet for given userId: ${userId}`);
		}

		let outstandingAmount = amount;
		if (!wallet.balance || wallet.balance.available === 0) {
			return outstandingAmount;
		}

		if (amount >= wallet.balance.available) {
			const currentBalance = wallet.balance;
			outstandingAmount = amount - currentBalance.available;
			await this.updateUserWallet(userId, {
				balance: { current: 0, available: 0 },
			});

			return outstandingAmount;
		}

		outstandingAmount = 0;
		const currentBalance = wallet.balance;
		const newBalance = currentBalance.available - amount;
		await this.updateUserWallet(userId, {
			balance: { current: newBalance, available: newBalance },
		});

		return outstandingAmount;
	}
}
