import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { ENVIRONMENT, Environment } from '@wezacommon/ng-models';

@Component({
	selector: 'apps-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
	constructor(
		@Inject(ENVIRONMENT) private env: Environment,
		@Inject(DOCUMENT) private document: Document
	) {}

	openCompliance() {
		this.document.location.href = this.env.url.compliance;
	}

	openInvoicing() {
		this.document.location.href = this.env.url.invoicing;
	}
}
