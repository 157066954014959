import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';

// TODO: error handling
// TODO: update to use AngularFire v7 modular code

@Injectable({ providedIn: 'root' })
export class DocumentStorageService {
	constructor(private readonly storage: AngularFireStorage) {}

	/**
	 * Uploads the document to firebase storage bucket
	 * @param path The custom document path to storage
	 * @param file The document to be uploaded
	 * @returns The uploading tasks or null if path and file are invalid
	 */
	upload(path: string, file: File): AngularFireUploadTask | null {
		if (path && file) {
			return this.storage.upload(path, file);
		}

		return null;
	}

	/**
	 * @returns Document's download URL
	 */
	getDownloadUrl$(path: string) {
		const bucket = this.storage.ref(path);
		return bucket.getDownloadURL();
	}

	/**
	 * Deletes document from Firebase storage bucket
	 */
	async deleteFromStorage(downloadURL: string): Promise<void> {
		await this.storage.storage.refFromURL(downloadURL).delete();
	}
}
