import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class FormValidatorService {
	userValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const errors: ValidationErrors = {};

			this._validateEmail(control, errors);
			this._validateFirstName(control, errors);
			this._validateLastName(control, errors);
			this._validatePhoneNumber(control, errors);

			return Object.keys(errors).length ? errors : null;
		};
	}

	passwordValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const errors: ValidationErrors = {};

			this._validatePassword(control, errors);
			this._validatePasswordMatch(control, errors);

			return Object.keys(errors).length ? errors : null;
		};
	}

	addressValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const errors: ValidationErrors = {};

			this.validateAddressLine1(control, errors);
			this.validateCity(control, errors);
			this.validatePostalCode(control, errors);

			return Object.keys(errors).length ? errors : null;
		};
	}

	signInValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const errors: ValidationErrors = {};

			this._validatePassword(control, errors);
			this._validateEmail(control, errors);

			return Object.keys(errors).length ? errors : null;
		};
	}

	subscriptionValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const errors: ValidationErrors = {};
			this._validatePhoneNumber(control, errors);

			return Object.keys(errors).length ? errors : null;
		};
	}

	private _validatePassword(
		control: AbstractControl,
		errors: ValidationErrors
	): void {
		const passwordControl = control.get('password');

		if (this._isRequired(passwordControl)) {
			errors['password'] = {
				message: 'Password is required',
			};
		}

		if (passwordControl?.value && passwordControl.value.length < 6) {
			errors['password'] = {
				message: 'The password must contain at least 6 characters.',
			};
		}
	}

	private _validateEmail(
		control: AbstractControl,
		errors: ValidationErrors
	): void {
		const emailControl = control.get('email');
		if (this._isRequired(emailControl)) {
			errors['email'] = {
				message: 'Email address is required',
			};
		}

		if (
			emailControl &&
			(emailControl.touched || emailControl.dirty) &&
			emailControl.errors &&
			emailControl.errors['email']
		) {
			errors['email'] = {
				message: 'Email address is not valid',
			};
		}
	}

	private _validateLastName(
		control: AbstractControl,
		errors: ValidationErrors
	): void {
		const firstNameControl = control.get('lastName');
		if (this._isRequired(firstNameControl)) {
			errors['lastName'] = {
				message: 'Last name is required',
			};
		}
	}

	private _validateFirstName(
		control: AbstractControl,
		errors: ValidationErrors
	): void {
		const firstNameControl = control.get('firstName');
		if (this._isRequired(firstNameControl)) {
			errors['firstName'] = {
				message: 'First name is required',
			};
		}
	}

	private validateCity(control: AbstractControl, errors: ValidationErrors): void {
		const city = control.get('city');
		if (this._isRequired(city)) {
			errors['city'] = {
				message: 'City is required.',
			};
		}
	}

	private validateAddressLine1(
		control: AbstractControl,
		errors: ValidationErrors
	): void {
		const addressLine1 = control.get('addressLine1');
		if (this._isRequired(addressLine1)) {
			errors['addressLine1'] = {
				message: 'Address Line1 is required.',
			};
		}
	}

	private validatePostalCode(
		form: AbstractControl,
		errors: ValidationErrors
	): void {
		const postalCode = form.get('postalCode');
		if (this._isRequired(postalCode)) {
			errors['postalCode'] = {
				message: 'Postal code is required.',
			};
		}
	}

	private _validatePhoneNumber(
		form: AbstractControl,
		errors: ValidationErrors
	): void {
		const phoneNumber = form.get('phoneNumber');
		if (this._isRequired(phoneNumber)) {
			errors['phoneNumber'] = {
				message: 'Phone number is required.',
			};
		}
	}

	private _validatePasswordMatch(
		control: AbstractControl,
		errors: ValidationErrors
	) {
		const passwordControl = control.get('password');
		const passwordMatchControl = control.get('passwordMatch');
		if (
			passwordMatchControl?.value &&
			passwordControl?.value !== passwordMatchControl.value
		) {
			errors['passwordMatch'] = {
				message: 'Passwords do not match',
			};
		}
	}

	private _isRequired(control: AbstractControl | null): boolean {
		return (
			control &&
			control.invalid &&
			control.errors &&
			control.errors['required'] &&
			(control.touched || control.dirty)
		);
	}
}
