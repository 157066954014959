<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
	<p>{{ data.message }}</p>
</div>
<br />
<div class="d-flex flex-column flex-sm-row">
	<button class="btn-weza" (click)="onCancel()">Cancel</button>
	&nbsp;
	<button class="btn-weza confirm-color" [mat-dialog-close]="'confirmed'">
		Confirm
	</button>
</div>
