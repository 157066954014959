import { DataSource } from '@angular/cdk/collections';
import { isDevMode } from '@angular/core';
import { QueryFn } from '@angular/fire/compat/firestore';
import { Compliance } from '@wezacommon/ng-models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ComplianceService } from './compliance.service';

export class ComplianceDataSource<T, R> implements DataSource<Compliance<T, R>> {
	private readonly dataSubject = new BehaviorSubject<Compliance<T, R>[]>([]);
	private readonly loadingSubject = new BehaviorSubject<boolean>(false);
	private readonly lengthSubject = new BehaviorSubject<number>(0);
	public readonly loading$ = this.loadingSubject.asObservable();
	public readonly length$ = this.lengthSubject.asObservable();

	constructor(private readonly service: ComplianceService, private readonly query?: QueryFn) {}

	connect(): Observable<readonly Compliance<T, R>[]> {
		return this.dataSubject.asObservable();
	}

	disconnect(): void {
		this.dataSubject.complete();
		this.loadingSubject.complete();
		this.lengthSubject.complete();
	}

	load(): void {
		this.loadingSubject.next(true);
		this.service
			.observeCollection<T, R>(this.query)
			.pipe(
				catchError(() => of([])),
				finalize(() => {
					this.lengthSubject.next(0);
					this.loadingSubject.next(false);
				})
			)
			.subscribe((collection: Compliance<T, R>[]) => {
				if (isDevMode()) {
					console.groupCollapsed('[ComplianceDataSource][load] collection:');
					console.log(collection);
					console.groupEnd();
				}

				this.loadingSubject.next(false);
				this.dataSubject.next(collection);
				this.lengthSubject.next(collection.length);
			});
	}
}
