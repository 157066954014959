import { Injectable } from '@angular/core';
import { Editor, Toolbar } from 'ngx-editor';

@Injectable({ providedIn: 'root' })
export class EditorWrapperService {
	private _toolbar: Toolbar = [
		['bold', 'italic', 'underline'],
		['code', 'blockquote'],
		['ordered_list', 'bullet_list'],
		['text_color', 'background_color'],
		['align_left', 'align_center', 'align_right', 'align_justify'],
	];

	create() {
		return new Editor();
	}

	get toolbar() {
		return this._toolbar;
	}
}
