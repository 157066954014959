import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormErrorComponent, HeaderComponent, NavbarComponent } from '.';
import { MaterialModules } from './material-modules';

@NgModule({
	declarations: [FormErrorComponent, NavbarComponent, HeaderComponent],
	imports: [...MaterialModules, CommonModule, RouterModule],
	exports: [...MaterialModules, FormErrorComponent, NavbarComponent, HeaderComponent],
})
export class UiCommonModule {}
