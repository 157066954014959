import { Component, Input } from "@angular/core"

@Component({
	selector: "wezaonline-transaction-title",
	templateUrl: "./transaction-title.component.html",
	styleUrls: ["./transaction-title.component.scss"]
})
export class TransactionTitleComponent {
	@Input() title: string | undefined
	@Input() navigateUrl: string | undefined

	get navigateButtonText(): string {
		const urlParts = this.navigateUrl?.split("/")
		return urlParts ? `${urlParts[urlParts.length - 1]} Transaction` : ""
	}
}
